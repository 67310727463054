import React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout'

const NotFoundPage = ({ data }) => (
  <BackgroundImage
    fluid={[
      'linear-gradient(180deg,rgba(19,21,25,.9),rgba(19,21,25,.2))',
      data.background.childImageSharp.fluid,
    ]}
  >
    <Layout title={'Not found'}>
      <h1>NOT FOUND</h1>
      <p>Looks like you got lost into the sea...</p>
      <Link to='/'>Return to home page</Link>
    </Layout>
  </BackgroundImage>
)

export const query = graphql`
  query {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default NotFoundPage
